export enum PATHS {
  main = '/',
  courses = '/courses',
  course = '/courses/:courseName/*',
  qa_csharp = '/courses/qa_csharp',
  qa_python = '/courses/qa_python',
  qa_java = '/courses/qa_java',
  qa_js = '/courses/qa_js',
  frontend = '/courses/frontend',
  python = '/courses/python',
  ios = '/courses/ios',
  unity = '/courses/unity',
  manual_qa = '/courses/manual_qa',
  project_manager = '/courses/project_manager',
  uxui = '/courses/uxui',
  system_analyst = '/courses/system_analyst',
  one_c = '/courses/one_c',
  data_engineer = '/courses/data_engineer',

  privacy = 'https://teachmeskills.ru/politika-obrabotki-personalnyh-dannyh',
  vk = 'https://vk.com/teachmeskills',
  telegram = 'https://t.me/TMS_news',
  youtube = 'https://www.youtube.com/channel/UCrpbZ8VZjn8FtMv0-I1VJ2A',
  teachers = 'https://teachmeskills.ru/teachers',
  blog = 'https://teachmeskills.ru/blog',
}
