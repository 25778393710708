import {
  IconsNames,
  YouWillLearnType,
} from 'components/atoms';
import { PATHS } from 'router/config';

export enum EducationForm {
  online = 'Дистанционный курс',
  offline = 'Офлайн курс',
}

export enum FooterCategoryNames {
  programming = 'Программирование',
  testing = 'Тестирование',
  design = 'Дизайн',
  management = 'Менеджмент',
  analysis = 'Бизнес-анализ',
  hr = 'HR',
}

export enum CategoryNames {
  all = 'Все курсы',

  programming = 'Программирование',
  management = 'Менеджмент',
  analysis = 'Аналитика',
  design = 'Дизайн',
  testing = 'Тестирование',
  gameDev = 'GameDev',
  // hr = 'HR',
}

export enum Courses {
  frontend = 'frontend',
  python = 'python',
  ios = 'ios',
  unity = 'unity',
  manual_qa = 'manual_qa',
  qa_csharp = 'qa_csharp',
  qa_python = 'qa_python',
  qa_java = 'qa_java',
  qa_js = 'qa_js',
  project_manager = 'pm',
  uxui = 'uxui',
  system_analyst = 'system_analyst',
  one_c = 'one_c',
  data_engineer = 'data_engineer',
}

export const CoursesPathToKey = {
  'frontend':Courses.frontend,
  'python':Courses.python,
  'ios':Courses.ios,
  'unity':Courses.unity,
  'manual_qa':Courses.manual_qa,
  'qa_csharp':Courses.qa_csharp,
  'qa_python':Courses.qa_python,
  'qa_java':Courses.qa_java,
  'qa_js':Courses.qa_js,
  'project_manager':Courses.project_manager,
  'uxui':Courses.uxui,
  'system_analyst':Courses.system_analyst,
  'one_c':Courses.one_c,
  'data_engineer':Courses.data_engineer,
}

export enum Technologies {
  python = 'python',
  java = 'java',
  javaScript = 'javaScript',
  html = 'html',
  css = 'css',
  flask = 'flask',
  gitLab = 'gitLab',
  pyCharm = 'pyCharm',
  cPlusPlus = 'cPlusPlus',
  fastApi = 'fastApi',
}

export enum TechnologiesNames {
  python = 'Python',
  java = 'Java',
  javaScript = 'Java Script',
  html = 'HTML',
  css = 'CSS',
  flask = 'Flask',
  gitLab = 'GitLab',
  pyCharm = 'PyCharm',
  cPlusPlus = 'C++',
  fastApi = 'FastApi',
}

export interface ICourse {
  key: Courses;
  categoryName: CategoryNames;
  courseName: string;
  startDate: string;
  discountPrice: string;
  oldPrice: string;
  priceDiff: string;
  perMonth: string;
  img: IconsNames;
  link: string;
  educationFrom: EducationForm;
  duration: string;
  weWillTeach: string;
  // youWillLearn: YouWillLearnType[];
  futureKnowledge: string;
  // technologies: Technologies[];
  futureSalaryTitle: string;
  salary: string;
  programText: string;
  academyHours: string;
  lessons: string[];
}

export const AllCourses: ICourse[] = [
  {
    key: Courses.project_manager,
    img: IconsNames.cs_project,
    link: PATHS.project_manager,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.management,
    courseName: 'Project Manager в IT',
    startDate: '08 августа',
    discountPrice: '58 650 ₽',
    oldPrice: '69 000 ₽',
    priceDiff: '-10 350 ₽',
    perMonth: '3 258',
    duration: '4 месяца',
    weWillTeach: 'Научим с нуля управлению проектами в IT за 4 месяца',
    futureKnowledge: 'Курсы Project Manager в IT позволят вам начать работу в качестве менеджера на любом проекте в компании. Современный руководитель IT-проекта знает не только техники управления проектами, но и понимает, как работают актуальные технологии. Эти знания позволяют ему находить общий язык с командой, а также грамотно планировать выполнение проекта.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата Project Manager в IT',
    salary: '70 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '136 академических часов',
    lessons: [
      '1. Об IT в целом. Основы Computer Science',
      '2. Project Management в IT. Основы управления проектом',
      '3. Управление качеством проекта: риски, изменения, прогресс',
      '4. Гибкие методологии разработки ПО',
      '5. Управление командой. Основы коммуникации',
      '6. Подведение итогов курса',
      '7. Онлайн-тренинг "Трудоустройство в IT"',
      '8. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.qa_csharp,
    img: IconsNames.cs_qa_csharp,
    link: PATHS.qa_csharp,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на C#',
    startDate: '15 августа',
    discountPrice: '61 200 ₽',
    oldPrice: '72 000 ₽',
    priceDiff: '-10 800 ₽',
    perMonth: '3 400',
    duration: '5 месяцев',
    weWillTeach: 'Научим автоматизированному тестированию на C# за 5 месяцев.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО.    На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API. Познакомишься с современными тестовыми фреймворками, научишься создавать тестовую документацию, анализировать результаты тестов и составлять отчеты по ним.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата тестировщика на C#',
    salary: '65 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '140 академических часов',
    lessons: [
      '1. Введение в .NET и C#. Репозитории',
      '2. Введение в ООП. Обобщения. Исключения. Коллекции',
      '3. Введение в тестирование ПО',
      '4. Введение в автоматизацию тестирования',
      '5. Selenium WebDriver',
      '6. MStest, Xunit, Nunit. Репортинг',
      '7. GOF Patterns и Best Practices. Continuous integration.',
      '8. Подходы к построению тестов',
      '9. Автоматизация REST API. Работа с базой данных',
      '10. Консультация по дипломному проекту. Тестовое задание',
      '11. Онлайн-тренинг "Трудоустройство в IT"',
      '12. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.frontend,
    img: IconsNames.cs_fe,
    link: PATHS.frontend,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.programming,
    courseName: 'Front End разработчик',
    startDate: '15 августа',
    discountPrice: '81 600 ₽',
    oldPrice: '96 000 ₽',
    priceDiff: '-14 400 ₽',
    perMonth: '4 533',
    duration: '7 месяцев',
    weWillTeach: 'С нуля до трудоустройства: HTML, CSS и JavaScript за 7 месяцев.',
    futureKnowledge: 'Курсы Front End с нуля позволяют начинающим разработчикам создавать интерфейсы, с которыми взаимодействуют пользователи. Он знает HTML, CSS и JavaScript – язык, который работает на всех платформах и единственный язык программирования для браузеров.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата Front End разработчика',
    salary: '70 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '224 академических часа',
    lessons: [
      '1. Введение в HTML и CSS. Модель Flexbox. Позиционирование',
      '2. Продвинутая работа с CSS. Адаптивная вёрстка и автоматизация',
      '3. JavaScript. Базовый уровень. Функции, объекты, массивы',
      '4. JavaScript. События. Асинхронность. Прототипы и классы',
      '5. Введение TypeScript',
      '6. React',
      '7. Redux',
      '8. Основы работы с API',
      '9. Основы тестирования. Webpack',
      '10. Онлайн-тренинг "Трудоустройство в IT"',
      '11. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.ios,
    img: IconsNames.cs_ios,
    link: PATHS.ios,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.programming,
    courseName: 'iOS разработчик',
    startDate: '15 августа',
    discountPrice: '77 350 ₽',
    oldPrice: '91 000 ₽',
    priceDiff: '-13 650 ₽',
    perMonth: '4 297',
    duration: '5 месяцев',
    weWillTeach: 'Научим разработке мобильных приложений на Swift за 5 месяцев',
    futureKnowledge: 'На курсе тебя ждет обучение Swift с нуля, ты научишься проектировать и анимировать интерфейсы, разработаешь свое iOS приложение, узнаешь способы его монетизации и опубликуешь в App Store.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата iOS разработчика',
    salary: '70 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '204 академических часа',
    lessons: [
      '1. Введение в XCode и SWIFT',
      '2. ООП. Storyboard',
      '3. Базовая навигация',
      '4. Анимация. Solid-принципы',
      '5. Attributed Strings. Отображение ошибок. WebKit',
      '6. Работа с датами и управление памятью',
      '7. Многопоточность и работа с API',
      '8. Хранение данных, Core Data',
      '9. Работа с базой данных',
      '10. Основы безопасности',
      '11. Паттерны',
      '12. Процесс разработки и локализация',
      '13. Cocoapods. Геолокация. FireBase',
      '14. Монетизация',
      '15. SwiftUI. Combine.',
      '16. VIPER / CleanSwift',
      '17. UnitTesting',
      '18. Публикация приложения в Appstore',
      '19. Ретроспектива',
      '20. Онлайн-тренинг "Трудоустройство в IT"',
      '21. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.qa_js,
    img: IconsNames.cs_qa_js,
    link: PATHS.qa_js,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на JavaScript',
    startDate: '20 августа',
    discountPrice: '58 650 ₽',
    oldPrice: '69 000 ₽',
    priceDiff: '-10 350 ₽',
    perMonth: '3 258',
    duration: '4 месяца',
    weWillTeach: 'Научим автоматизированному тестированию на JavaScript за 4 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать и запускать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 2,
    //     title: 'Работать с фреймворками',
    //     text: 'Научишься работать с тестовыми фреймворками для модульного и API тестирования',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium и другим софтом',
    //     text: 'Научишься работать с Selenium Webdriver, WebDriverIO, Cypress и Playwright на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата тестировщика на JavaScript',
    salary: '65 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '124 академических часа',
    lessons: [
      '1. Введение в Typescript',
      '2. Введение в тестирование ПО',
      '3. Введение в автоматизацию тестирования',
      '4. Тестирование API. Selenium',
      '5. PageObjects. WebDriverIO. Playwright. Cypress',
      '6. Работа с базой данных. CI/CD',
      '7. Консультация по дипломному проекту',
      '8. Интервью',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',  
    ],
  },
  {
    key: Courses.qa_python,
    img: IconsNames.cs_qa_python,
    link: PATHS.qa_python,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на Python',
    startDate: '20 августа',
    discountPrice: '58 650 ₽',
    oldPrice: '69 000 ₽',
    priceDiff: '-10 350 ₽',
    perMonth: '3 258',
    duration: '4 месяца',
    weWillTeach: 'Научим автоматизированному тестированию на Python за 4 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium, файлами и базами данных',
    //     text: 'Научишься работать с Selenium Webdriver, файлами и базами данных на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата тестировщика на Python',
    salary: '65 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '132 академических часа',
    lessons: [
      '1. Введение в алгоритмизацию и экосистему Python',
      '2. Основы тестирования ПО',
      '3. Введение в Linux',
      '4. Модульное тестирование',
      '5. Введение в автоматизацию тестирования. Selenium WebDriver',
      '6. REST API. Базы данных. Docker',
      '7. Консультация по дипломному проекту и тестовое задание',
      '8. Онлайн-тренинг "Трудоустройство в IT"',
      '9. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.system_analyst,
    img: IconsNames.cs_system,
    link: PATHS.system_analyst,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.analysis,
    courseName: 'Системный аналитик',
    startDate: '21 августа',
    discountPrice: '29 750 ₽',
    oldPrice: '35 000 ₽',
    priceDiff: '-5 250 ₽',
    perMonth: '1 653',
    duration: '2 месяца',
    weWillTeach: 'Научим системному анализу с 0 до трудоустройства за 2 месяца.',
    futureKnowledge: 'Системный аналитик - это специалист, работающий с представителями бизнеса и командой разработки IT-решения. Системный аналитик, опираясь на требования к системе, вместе с разработчиками определяет наиболее эффективное техническое решение и документирует его, начиная от архитектуры и заканчивая интерфейсами взаимодействия.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium, файлами и базами данных',
    //     text: 'Научишься работать с Selenium Webdriver, файлами и базами данных на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата системеного аналитика',
    salary: '45 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '60 академических часов',
    lessons: [
      '1. Описание прототипов интерфейсов',
      '2. Интеграция систем и документирование API',
      '3. Введение в архитектуру',
      '4. Моделирование процессов',
      '5. SQL для анализа данных',
      '6. Основы современной разработки ПО',
      '7. Защита дипломных проектов',
      '8. Онлайн-тренинг "Трудоустройство в IT"',
    ],
  },
  {
    key: Courses.qa_java,
    img: IconsNames.cs_qa_java,
    link: PATHS.qa_java,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.testing,
    courseName: 'Автоматизированное тестирование на Java',
    startDate: '22 августа',
    discountPrice: '61 200 ₽',
    oldPrice: '72 000 ₽',
    priceDiff: '-10 800 ₽',
    perMonth: '3 400',
    duration: '4,5 месяца',
    weWillTeach: 'Научим автоматизированному тестированию на Java за 4,5 месяца.',
    futureKnowledge: 'Automation QA Engineer разрабатывает автоматические тесты для нахождения уязвимостей и проверки ПО. На курсе QA Engineer ты научишься писать автотесты для web-приложений и REST API, создавать документацию и грамотную отчетность.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата тестировщика на Java',
    salary: '65 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '152 академических часа',
    lessons: [
      '1. Введение в Java',
      '2. Введение в тестирование ПО',
      '3. Введение в автоматизацию тестирования',
      '4. Selenium WebDriver. JUnit, TestNG. Maven',
      '5. GOF Patterns и Best Practices. Continuous integration – CircleCI',
      '6. Автоматизация REST API. Работа с базой данных',
      '7. Консультация по дипломному проекту',
      '8. Интервью',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.data_engineer,
    img: IconsNames.cs_data_engineer,
    link: PATHS.data_engineer,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.programming,
    courseName: 'Инженер данных',
    startDate: '22 августа',
    discountPrice: '74 800 ₽',
    oldPrice: '88 000 ₽',
    priceDiff: '-13 200 ₽',
    perMonth: '4 156',
    duration: '6 месяцев',
    weWillTeach: 'Стань востребованным Data-инженером c нуля через 6 месяцев.',
    futureKnowledge: 'Курс Data Engineering в нашей школе предоставит вам возможность стать специалистом в области обработки данных за короткий срок всего в 6 месяцев. Программа разработана опытными профессионалами, активно работающими в индустрии, что обеспечит вам не только теоретические знания, но и практические навыки, необходимые для успешной карьеры в данной области.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата инженера данных',
    salary: '110 000 RUB',
    programText: 'Программа курса Data Engineering разработана для охвата всего материала, необходимого для становления профессиональным специалистом в данной области.',
    academyHours: '200 академических часов',
    lessons: [
      '1. Введение. Роли и задачи Data Engineer',
      '2. Введение в Python',
      '3. Моделирование данных',
      '4. Интеграция данных и ETL процессы',
      '5. Введение в Apache Airflow',
      '6. Практические проекты',
      '7. Подготовка к дипломному проекту',
      '8. Онлайн-занятие «Поиск работы в IT»',
      '9. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.unity,
    img: IconsNames.cs_unity,
    link: PATHS.unity,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.gameDev,
    courseName: 'Разработчик игр на Unity ',
    startDate: '26 августа',
    discountPrice: '72 250 ₽',
    oldPrice: '85 000 ₽',
    priceDiff: '-12 750 ₽',
    perMonth: '4 014',
    duration: '6,5 месяца',
    weWillTeach: 'Научим разработке игр на Unity за 6,5 месяцев.',
    futureKnowledge: 'Unity - это современный и мощный игровой движок, позволяющий делать игры любого уровня.  Unity-разработчик создаёт игры и приложения почти под все игровые платформы.  На курсе тебя ждет не только изучение движка Unity, но и языка программирования C# с нуля.  К тому же ты научишься создавать игры от стадии идеи до загрузки в Google Play/AppStore, используя данные инструменты и возможности.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата разработчика игр на Unity',
    salary: '50 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '196 академических часов',
    lessons: [
      '1. O Unity и его возможностях. Начало проекта Shooter.',
      '2. С# и игра Hangman',
      '3. ООП. Создание игры HOG (Hidden Object Game)',
      '4. Runner с генераций уровня',
      '5. Геймдизайн. Теоретический минимум по Computer Science',
      '6. Игра Idle',
      '7. Платформер',
      '8. Match 3. Крестики-нолики в AR',
      '9. Игра пасьянс (косынка)',
      '10. Внедрение улучшений в Shooter 3D',
      '11. Закрепление пройденного материала',
      '12. Онлайн-тренинг "Трудоустройство в IT"',
      '13. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.manual_qa,
    img: IconsNames.cs_manual_qa,
    link: PATHS.manual_qa,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.testing,
    courseName: 'Тестирование веб и мобильных приложений',
    startDate: '27 августа',
    discountPrice: '48 450 ₽',
    oldPrice: '57 000 ₽',
    priceDiff: '-8 550 ₽',
    perMonth: '2 692',
    duration: '4 месяца',
    weWillTeach: 'Научим ручному тестирование веб и мобильных приложений за 4 месяца.',
    futureKnowledge: 'Manual QA Engineer проводит анализ приложений и дальнейшее тестирование, доводя продукт до конечного пользователя в стабильном и исправном состоянии.    На курсе ты научишься работать с требованиями, разрабатывать тестовую документацию, узнаешь о различных подходах и техниках тестирования в условиях различных методологий разработки ПО. А самое главное - ты узнаешь, как тестировать реальные веб и мобильные приложения (iOS/Android)! Мы поделимся своим опытом и наработками, которые тебе помогут войти в IT с легкой ноги.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата тестировщика веб и мобильных приложений',
    salary: '50 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '104 академических часа',
    lessons: [
      '1. Введение в тестирование приложений',
      '2. Клиент-серверная архитектура. API. Базы данных.',
      '3. Тестирование веб-приложений',
      '4. Мобильные приложения (Android/iOS): сравнения, выбор устройств для мобильного тестирования',
      '5. Работа с Android-приложением',
      '6. Работа с iOS-приложением',
      '7. Тестирование мобильных приложений: проверки, сложности, особенности выкладки, инструменты',
      '8. Подведение итогов курса',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.uxui,
    img: IconsNames.cs_uxui,
    link: PATHS.uxui,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.design,
    courseName: 'UX/UI дизайнер  ',
    startDate: '28 августа',
    discountPrice: '61 200 ₽',
    oldPrice: '72 000 ₽',
    priceDiff: '-10 800 ₽',
    perMonth: '3 400',
    duration: '4 месяца',
    weWillTeach: 'Научим проектировать интерфейсы мобильных приложений и web-сайтов за 4 месяца.',
    futureKnowledge: 'В ходе курса ты спроектируешь множество интерфейсов и будешь готов к решению большинства задач, стоящих перед UX/UI дизайнером. Ты освоишь Figma и другие современные инструменты.    Среди практических заданий будет проектирование интерфейсов iOS или Android приложений, а также web-сайтов разной сложности. Это поможет составить по результатам курса сильное портфолио. А благодаря знаниям UX-research ты сможешь начать проектировать интерфейсы, которые действительно полюбят пользователи.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата UX/UI дизайнера',
    salary: '60 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '132 академических часа',
    lessons: [
      '1. Понятие дизайна и дизайн-процесса',
      '2. Дизайн-задача и UX исследования',
      '3. Выдвижение гипотез. Поиск дизайн-решения',
      '4. Figma',
      '5. Принципы проектирования интерфейсов',
      '6. Базовые элементы пользовательского интерфейса',
      '7. Типографика и вёрстка',
      '8. Оформление кейса в портфолио',
      '9. Консультации по дипломному проекту',
      '10. Онлайн-тренинг "Трудоустройство в IT"',
      '11. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.one_c,
    img: IconsNames.cs_one_c,
    link: PATHS.one_c,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.programming,
    courseName: '1С разработчик ',
    startDate: '28 августа',
    discountPrice: '56 100 ₽',
    oldPrice: '66 000 ₽',
    priceDiff: '-9 900 ₽',
    perMonth: '3 117',
    duration: '3,5 месяца',
    weWillTeach: 'Подготовим к сдаче экзамена по 1С специалист c нуля за 3,5 месяца',
    futureKnowledge: 'Онлайн-обучение работе с программами от «1С» в школе TeachMeSkills решает сразу две задачи. Во-первых, ты осваиваешь платформу и язык, напоминающий Visual Basic с конструкциями и комментариями из C++. Во-вторых, менторы готовят тебя к сдаче аттестаций «1С:Специалист» и «1С:Специалист-консультант», разбирая типовые задания, прикладные решения или методы конфигурирования продуктов.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата разработчика на 1С',
    salary: '50 000 RUB',
    programText: 'Программу всех занятий по 1С в нашей школе программирования разрабатывают и ведут практикующие IT специалисты.',
    academyHours: '112 академических часов',
    lessons: [
      '1. Установка, настройка, основные функции',
      '2. Работа с объектами конфигурации',
      '3. Конструктор запросов',
      '4. Бухгалтерский учёт',
      '5. Использование бизнес-процессов',
      '6. Работа с данными',
      '7. API (12) и дополнительные конструкторы',
      '8. СКД',
      '9. Ретроспектива',
      '10. Онлайн-занятие «Поиск работы в IT»',
      '11. Защита дипломного проекта',
    ],
  },
  {
    key: Courses.python,
    img: IconsNames.cs_python,
    link: PATHS.python,
    educationFrom: EducationForm.online,
    // technologies: [Technologies.python, Technologies.java, Technologies.javaScript, Technologies.html, Technologies.css, Technologies.gitLab, Technologies.flask, Technologies.pyCharm, Technologies.cPlusPlus, Technologies.fastApi],
    categoryName: CategoryNames.programming,
    courseName: 'Python разработчик',
    startDate: '28 августа',
    discountPrice: '74 800 ₽',
    oldPrice: '88 000 ₽',
    priceDiff: '-13 200 ₽',
    perMonth: '4 156',
    duration: '5,5 месяцев',
    weWillTeach: 'Разработка web-приложений на Python за 5,5 месяцев с нуля и до трудоустройства',
    futureKnowledge: 'Язык программирования Python является одним из лучших для обучения с нуля. Простой синтаксис + высокая скорость разработки – это все, что нужно для создания широкого спектра web-приложений. Курс Python с нуля в IT-школе TeachMeSkills поможет тебе изучить экосистему Python, научиться разрабатывать приложения на фреймворке Django, а также работать с серверами и базами данных.',
    // youWillLearn: [
    //   {
    //     id: 1,
    //     title: 'Создавать автотесты',
    //     text: 'Для тестирования web-приложений и REST API, используя лучшие практики в индустрии',
    //   },
    //   {
    //     id: 2,
    //     title: 'Запускать тесты',
    //     text: 'Используя инструменты Continious Integration',
    //   },
    //   {
    //     id: 3,
    //     title: 'Работать с Selenium',
    //     text: 'Научишься работать с Selenium Webdriver на продвинутом уровне',
    //   },
    //   {
    //     id: 4,
    //     title: 'Писать документацию',
    //     text: 'Напишешь грамотную документацию и отчет тестирования',
    //   },
    // ],
    futureSalaryTitle: 'Зарплата Python разработчика',
    salary: '90 000 RUB',
    programText: 'Программа курса составлена практикующими IT-специалистами для новичков на основе требований IT компаний.',
    academyHours: '200 академических часов',
    lessons: [
      '1. Введение в алгоритмизацию и экосистему Python. Основы.',
      '2. ООП. Базы данных и SQL. Flask',
      '3. Django. Представления. Шаблоны. Django REST.',
      '4. JWT Auth. Документирование API. Celery',
      '5. Асинхронный Python. Aiohttp',
      '6. DevOps',
      '7. Введение в РМ инструменты',
      '8. Подготовка к дипломному проекту',
      '9. Онлайн-тренинг "Трудоустройство в IT"',
      '10. Защита дипломного проекта',
    ],
  },
];
