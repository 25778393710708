import { useEffect, useState } from 'react';

export const useGetTimeLeft = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: '0',
    hours: '0',
    minutes: '0',
    seconds: '0',
  });
  const year = new Date().getFullYear();
  const difference =
    +new Date(`${year}-08-17T00:00:00.000+03:00`) - +new Date();

  useEffect(() => {
    const id = setTimeout(() => {
      if (difference > 0) {
        setTimeLeft(() => {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((difference / 1000 / 60) % 60);
          const seconds = Math.floor((difference / 1000) % 60);

          return {
            days: days < 10 ? `0${days}` : `${days}`,
            hours: hours < 10 ? `0${hours}` : `${hours}`,
            minutes: minutes < 10 ? `0${minutes}` : `${minutes}`,
            seconds: seconds < 10 ? `0${seconds}` : `${seconds}`,
          };
        });
      }
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  });

  return timeLeft;
};
